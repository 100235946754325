exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-experience-tsx": () => import("./../../../src/pages/experience.tsx" /* webpackChunkName: "component---src-pages-experience-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-tsx": () => import("./../../../src/pages/landing.tsx" /* webpackChunkName: "component---src-pages-landing-tsx" */),
  "component---src-pages-other-projects-tsx": () => import("./../../../src/pages/other-projects.tsx" /* webpackChunkName: "component---src-pages-other-projects-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-skills-tsx": () => import("./../../../src/pages/skills.tsx" /* webpackChunkName: "component---src-pages-skills-tsx" */),
  "component---src-pages-whats-next-tsx": () => import("./../../../src/pages/whats-next.tsx" /* webpackChunkName: "component---src-pages-whats-next-tsx" */)
}

